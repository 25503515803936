import { useEffect } from "preact/hooks";
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from "../SVGs.tsx";

function Notification({
  showNotification,
  closeNotification,
  notificationType,
  message,
}: any) {
  const notificationDuration = 3000;

  useEffect(() => {
    if (showNotification) {
      const timeoutId = setTimeout(() => {
        closeNotification();
      }, notificationDuration);

      return () => clearTimeout(timeoutId);
    }
  }, [showNotification, closeNotification]);

  const getIconColor = () => {
    if (notificationType === "success") return "text-green-800";
    if (notificationType === "info") return "text-blue-800";
    return "text-red-800";
  };

  const getIconBgColor = () => {
    if (notificationType === "success") return "bg-green-100";
    if (notificationType === "info") return "bg-blue-100";
    return "bg-red-100";
  };

  return (
    <>
      {showNotification && (
        <div
          id="toast-default"
          className={`fixed top-14 z-[999] right-0 m-4 p-4 rounded-lg shadow text-gray-400 ${
            notificationType === "success"
              ? "bg-green-500"
              : notificationType === "info"
              ? "bg-blue-500"
              : "bg-red-500"
          }`}
          role="alert"
        >
          <div className="flex items-center justify-center gap-4">
            <div
              className={`flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-lg ${getIconBgColor()} ${getIconColor()} bg-opacity-80`}
            >
              {notificationType === "success"
                ? <SuccessIcon />
                : notificationType === "info"
                ? <InfoIcon />
                : <ErrorIcon />}
              <span className="sr-only">{notificationType} icon</span>
            </div>
            <div className="ms-3 text-xs font-medium text-white w-[220px]">
              {message}
            </div>
            <button
              type="button"
              class="ms-auto -mx-1.5 -my-1.5  text-gray-100 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex items-center justify-center h-5 w-5 bg-gray-800 hover:bg-gray-700"
              data-dismiss-target="#toast-default"
              onClick={closeNotification}
              aria-label="Close"
            >
              <span class="sr-only">Close</span>
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Notification;
